// Generated by Framer (838580a)

import { addFonts, cx, CycleVariantState, FormPlainTextInput, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Nd0N1ymwJ"];

const serializationHash = "framer-MGr8r"

const variantClassNames = {Nd0N1ymwJ: "framer-v-lbz8o8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Nd0N1ymwJ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.label {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lbz8o8", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Nd0N1ymwJ"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-r6o4lv, rgb(136, 136, 136))"}}>Name</motion.p></React.Fragment>} className={"framer-spzdwj"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"QzZLGT2TB"} style={{"--extracted-r6o4lv": "rgb(136, 136, 136)"}} verticalAlignment={"top"} withExternalLayout/><FormPlainTextInput className={"framer-qheu4o"} inputName={"Name"} layoutDependency={layoutDependency} layoutId={"Ip1AKoVgi"} placeholder={"Jane Smith"} style={{"--framer-input-background": "rgba(187, 187, 187, 0.15)", "--framer-input-border-bottom-width": "1px", "--framer-input-border-color": "rgba(136, 136, 136, 0.1)", "--framer-input-border-left-width": "1px", "--framer-input-border-radius-bottom-left": "10px", "--framer-input-border-radius-bottom-right": "10px", "--framer-input-border-radius-top-left": "10px", "--framer-input-border-radius-top-right": "10px", "--framer-input-border-right-width": "1px", "--framer-input-border-style": "solid", "--framer-input-border-top-width": "1px", "--framer-input-font-color": "rgb(153, 153, 153)", "--framer-input-icon-color": "rgb(153, 153, 153)", "--framer-input-placeholder-color": "rgb(153, 153, 153)"}} type={"text"}/></motion.label></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MGr8r.framer-70ym9d, .framer-MGr8r .framer-70ym9d { display: block; }", ".framer-MGr8r.framer-lbz8o8 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; padding: 0px; position: relative; width: 461px; }", ".framer-MGr8r .framer-spzdwj { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-MGr8r .framer-qheu4o { --framer-input-focused-border-color: #0099ff; --framer-input-focused-border-style: solid; --framer-input-focused-border-width: 1px; --framer-input-font-family: \"Inter\"; --framer-input-font-letter-spacing: 0em; --framer-input-font-line-height: 1.2em; --framer-input-font-size: 14px; --framer-input-font-weight: 400px; --framer-input-padding: 12px; flex: none; height: 40px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MGr8r.framer-lbz8o8 { gap: 0px; } .framer-MGr8r.framer-lbz8o8 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-MGr8r.framer-lbz8o8 > :first-child { margin-top: 0px; } .framer-MGr8r.framer-lbz8o8 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 461
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOVUTZyAfx: React.ComponentType<Props> = withCSS(Component, css, "framer-MGr8r") as typeof Component;
export default FramerOVUTZyAfx;

FramerOVUTZyAfx.displayName = "form";

FramerOVUTZyAfx.defaultProps = {height: 64, width: 461};

addFonts(FramerOVUTZyAfx, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2", weight: "500"}, {family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZ1rib2Bg-4.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})